import './App.css';
import { useState, useEffect } from "react";
import Loader from "react-spinners/HashLoader";

function App() {
  const [loading, setLoading] = useState(true);
  const [workers, setWorkers] = useState([]);
  useEffect(() => {
    fetch("https://workers.xn--s88ha.ml/")
    .then(data => data.json())
    .then(json => setWorkers(json))
    .then(() => setLoading(false));
  }, []);
  return <>
    {loading ? 
      <div class="App">
        <Loader loading={loading} />
      </div>
    :
      <div class="App">
        {workers.map(w => <a href={`https://${w}`} key={w}>{w.split(".").slice(0, -2).join(".")}</a>)}
      </div>
    }
  </>
}

export default App;
